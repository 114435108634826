import React from 'react';
import Helmet from 'react-helmet';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';

import * as styles from './projects.scss';

const Page = ({ data }) => (
  <Layout>
    <Helmet>
      <body page-name="projects" />
    </Helmet>
    <main>
      <h2>
        <Link to="/projects">projects</Link> / SDC System
      </h2>
      <h1>SDC Team & Learning Management System</h1>
      <p>
        A web application used by teaching staff and students at the{' '}
        <a href="https://sdc.csc.ncsu.edu">Senior Design Center</a> (NCSU Dept.
        Computer Science) to manage day-to-day academic and administrative
        processes.
      </p>

      <p>
        Started in 2016, I served as principal developer of the frontend UI and
        assisted in development of the backend.
      </p>

      <p>
        <em>Technologies:</em> PHP/LAMP stack (backend), AngularJS (frontend)
      </p>

      <h2>Functionality Highlights</h2>

      <figure>
        <Img
          alt="student dashboard"
          className="project-screenshot"
          fluid={data.studentDashboard.childImageSharp.fluid}
        />
        <figcaption>student dashboard</figcaption>
      </figure>

      <figure>
        <Img
          alt="staff dashboard"
          className="project-screenshot"
          fluid={data.staffDashboard.childImageSharp.fluid}
        />
        <figcaption>staff dashboard</figcaption>
      </figure>

      <h3>Student registration & project bids</h3>

      <p>
        The system automatically creates user accounts from the official
        university registrar records and places students into the correct course
        section.
      </p>

      <p>
        In the first week of class, students use the system to bid for the
        projects they want to work on. Each student chooses their top 3 choices
        of project and writes a brief statement for each describing why they
        chose it. They may optionally specify teammate preferences. The teaching
        staff use this information to assign students to teams.
      </p>

      <h3>Team and advisor assignments</h3>

      <p>
        The teaching staff use the system to create teams and assign users as
        students or advisors to those teams. Teams are the core entities in the
        system; all other functionality, roles, and permissions are derived from
        a user's relationship to any given team.
      </p>

      <figure>
        <Img
          alt="staff dashboard"
          className="project-screenshot"
          fluid={data.teamFormation.childImageSharp.fluid}
        />
        <figcaption>team member assignments</figcaption>
      </figure>

      <h3>GitHub repository provisioning</h3>

      <p>
        After teams are assigned, the system automates the creation of private
        GitHub repositories for each team. It automatically assigns the correct
        permissions for each relevant user – students have write/push access,
        and advisors and sponsors have read-only access.
      </p>

      <p>
        At the end of every week, the system generates an overview of team
        activity on GitHub – commits, issues, PRs, etc. – and sends an aggregate
        report to each advisor for their relavent teams.
      </p>

      <h3>Team deliverables submissions</h3>

      <p>
        Throughout the semester, teams are required to submit a variety of
        deliverables, including written documents, presentations (slides), and
        poster designs. All assignment submissions are handled through the
        system; any member of a team can submit an assignment on behalf of their
        team, and each member can see all of the team's assignments.
      </p>

      <figure>
        <Img
          className="project-screenshot"
          fluid={data.staffSubmissionsView.childImageSharp.fluid}
        />
        <figcaption>assignments overview (staff)</figcaption>
      </figure>

      <p>
        The system automatically notifies students when assignment lockers open,
        and also when the assignment is near due if their team has not made any
        submissions. Students and teaching staff are notified when submissions
        are made for any of their teams.
      </p>

      <figure>
        <Img
          className="project-screenshot"
          fluid={data.emailFileSubmitted.childImageSharp.fluid}
        />
        <figcaption>email notification of assignment submission</figcaption>
      </figure>

      <h3>Incremental & staged advisor feedback process for reports</h3>

      <p>
        Written feedback on assignments is a collaborative effort from the
        teaching staff, as each team has multiple advisors. Advisors use the
        system to download files submitted by students and upload their own
        feedback as comments or annotations on the files, which are initially
        hidden from students.
      </p>

      <p>
        The system allows advisors to monitor the status of feedback on their
        teams' assignments, seeing at a glance which assignments have been, or
        are currently being, reviewed. After all advisors have uploaded feedback
        on an assignment, a single version of combined feedback can be released
        to students.
      </p>

      <figure>
        <Img
          alt="staff locker view"
          className="project-screenshot"
          fluid={data.staffLockerView.childImageSharp.fluid}
        />
        <figcaption>assignment submissions listing (staff)</figcaption>
      </figure>

      <figure>
        <Img
          alt="student log entry interface"
          className="project-screenshot"
          fluid={data.feedbackDetail.childImageSharp.fluid}
        />
        <figcaption>submission feedback detail (staff)</figcaption>
      </figure>

      <figure>
        <Img
          className="project-screenshot"
          fluid={data.emailFeedback.childImageSharp.fluid}
        />
        <figcaption>email notification of available feedback</figcaption>
      </figure>

      <h3>Individual student work logs & reflections</h3>

      <p>
        Each student is expected to keep a written log of their work for the
        entire semester. The system allows them to write log entries in
        plaintext or markdown, providing flexibility in how they can express
        reflections on their work.
      </p>

      <figure>
        <Img
          alt="student log entry interface"
          className="project-screenshot"
          fluid={data.logEntry.childImageSharp.fluid}
        />
        <figcaption>student log entry</figcaption>
      </figure>

      <p>
        Students can see a reverse-chronological list of all their log entries
        grouped by week. The system provides a summary overview of their total
        work for the semester, breaking down hours by category and providing
        guidance on whether they are on track to meet the total expected hours
        by the end of the semester.
      </p>

      <figure>
        <Img
          alt="student log view"
          className="project-screenshot"
          fluid={data.studentLogView.childImageSharp.fluid}
        />
        <figcaption>student log view</figcaption>
      </figure>

      <p>
        Teaching staff use the system to read log entries of students on the
        teams they advise. Advisors can view logs of an individual or combined
        logs of a team. The system provides an overview graph of work hours over
        time. It also allows teaching staff to compare the hours of team members
        to monitor for possible problematic distributions of workloads.
      </p>

      <figure>
        <Img
          style={{
            maxWidth: '25em',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          alt="staff weekly log view"
          className="project-screenshot"
          fluid={data.weeklyLogView.childImageSharp.fluid}
        />
        <figcaption>weekly log view of individual student (staff)</figcaption>
      </figure>

      <h3>Peer evaluations</h3>

      <p>
        Students submit monthly peer evaluations of their team members and
        summaries of personal contributions. The system allows them to provide
        numeric scores on a rubric as well as provide open-ended qualitative
        feedback.
      </p>

      <p>
        Teaching staff use the system to analyze the peer evaluations submitted
        by the teams they advise. The system provides an overview of aggregate
        peer scores as well as functionality to view individual scores.
      </p>

      <figure>
        <Img
          style={{
            maxWidth: '25em',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
          className="project-screenshot"
          fluid={data.pevalsOverview.childImageSharp.fluid}
        />
        <figcaption>peer eval scores overview (without names)</figcaption>
      </figure>

      <figure>
        <Img
          className="project-screenshot"
          fluid={data.pevalsDetail.childImageSharp.fluid}
        />
        <figcaption>individual peer eval score detail</figcaption>
      </figure>
    </main>
  </Layout>
);

export const query = graphql`
  query {
    staffDashboard: file(
      relativePath: { eq: "projects/sdc-system/staff-dashboard.png" }
    ) {
      ...fluidImg
    }
    studentDashboard: file(
      relativePath: { eq: "projects/sdc-system/student-dashboard.png" }
    ) {
      ...fluidImg
    }
    logEntry: file(relativePath: { eq: "projects/sdc-system/log-entry.png" }) {
      ...fluidImg
    }
    studentLogView: file(
      relativePath: { eq: "projects/sdc-system/student-log-view.png" }
    ) {
      ...fluidImg
    }
    staffSubmissionsView: file(
      relativePath: { eq: "projects/sdc-system/staff-submissions-view.png" }
    ) {
      ...fluidImg
    }
    staffLockerView: file(
      relativePath: { eq: "projects/sdc-system/staff-locker-view.png" }
    ) {
      ...fluidImg
    }
    weeklyLogView: file(
      relativePath: { eq: "projects/sdc-system/weekly-log-view.png" }
    ) {
      ...fluidImg
    }
    teamFormation: file(
      relativePath: { eq: "projects/sdc-system/team-assignments.png" }
    ) {
      ...fluidImg
    }
    feedbackDetail: file(
      relativePath: { eq: "projects/sdc-system/feedback-detail.png" }
    ) {
      ...fluidImg
    }
    emailFileSubmitted: file(
      relativePath: { eq: "projects/sdc-system/email-file-submitted.png" }
    ) {
      ...fluidImg
    }
    emailFeedback: file(
      relativePath: { eq: "projects/sdc-system/email-feedback.png" }
    ) {
      ...fluidImg
    }
    pevalsOverview: file(
      relativePath: { eq: "projects/sdc-system/pevals-overview.png" }
    ) {
      ...fluidImg
    }
    pevalsDetail: file(
      relativePath: { eq: "projects/sdc-system/pevals-detail.png" }
    ) {
      ...fluidImg
    }
  }
`;

export default Page;
